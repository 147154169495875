import React from 'react'
import { InternalIconProps } from '../Icon.types'

const Saved = ({
  colors: { primary, secondary, tertiary },
  ...props
}: InternalIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill={secondary}
      d="M26.967 12.193c.009-.805-.14-1.604-.44-2.35-.3-.747-.742-1.427-1.304-2.002-.562-.575-1.231-1.034-1.97-1.35-.738-.315-1.531-.482-2.334-.491-.974.003-1.932.244-2.792.701-.86.458-1.595 1.118-2.143 1.925-.548-.807-1.284-1.467-2.144-1.925-.86-.457-1.818-.698-2.791-.701-.803.009-1.596.176-2.335.491-.738.316-1.408.775-1.97 1.35-.561.575-1.004 1.255-1.303 2.002-.3.746-.45 1.545-.44 2.35.158 1.847.963 3.579 2.27 4.89L16.006 26l8.734-8.928c1.286-1.321 2.083-3.042 2.26-4.88"
    />
    <path
      fill={primary}
      fillRule="evenodd"
      d="M14.61 6.394c-.297-.234-.616-.442-.952-.622-.94-.504-1.986-.769-3.05-.772-.877.01-1.743.193-2.55.54-.807.348-1.538.852-2.152 1.485-.614.632-1.098 1.38-1.425 2.202-.327.822-.49 1.7-.48 2.585.173 2.032 1.052 3.937 2.48 5.38L16.024 27l9.542-9.82c1.392-1.46 2.25-3.352 2.434-5.368.01-.885-.154-1.763-.481-2.585-.327-.821-.811-1.57-1.425-2.202-.614-.633-1.345-1.137-2.152-1.484-.806-.348-1.673-.532-2.55-.541-1.064.003-2.11.268-3.05.772-.336.18-.655.388-.953.622C16.854 6.814 16.385 7.43 16 8c-.385-.57-.854-1.185-1.39-1.606zM21.385 7c-.73.005-1.45.188-2.098.535-.651.349-1.21.854-1.628 1.472L16 11l-1.658-1.993c-.417-.618-.977-1.123-1.628-1.472-.648-.347-1.367-.53-2.098-.535-.607.008-1.208.136-1.767.377-.564.243-1.077.596-1.507 1.04-.431.445-.772.971-1.002 1.55-.222.556-.337 1.15-.34 1.75.15 1.54.823 2.977 1.903 4.067l.006.006L16 24l8.125-8.207c1.056-1.11 1.717-2.546 1.875-4.08-.003-.6-.119-1.191-.34-1.746-.23-.58-.57-1.105-1.002-1.55-.43-.444-.943-.797-1.507-1.04-.56-.24-1.16-.369-1.767-.377z"
      clipRule="evenodd"
    />
    <path
      fill={tertiary}
      fillRule="evenodd"
      d="M15.293 20.707l-6-6 1.414-1.414 6 6-1.414 1.414z"
      clipRule="evenodd"
    />
  </svg>
)

export default Saved
