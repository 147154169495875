import React, { SVGProps } from 'react'

export const Rainbow: React.FC<SVGProps<SVGSVGElement>> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="249"
    height="112"
    fill="none"
    viewBox="0 0 249 112"
  >
    <mask
      id="zdgx7epcia"
      width="249"
      height="64"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M20.043 62.585v-16.68h7.245c16.432 0 23.204-7.269 23.204-22.952C50.492 7.17 43.72 0 27.288 0H0v62.585h20.043zm0-32.114V16.28h2.09c5.005 0 7.097 2.34 7.097 7.095s-2.092 7.095-7.096 7.095h-2.091zm56.766 32.114V43.316h.747c3.585 0 5.154.996 6.249 4.257l4.855 15.012h21.362l-5.079-14.514c-2.764-8.016-6.523-11.775-12.772-12.77l-1.594-.25v-.995c9.586-.25 16.184-6 16.184-16.605S101.507 0 87.391 0H56.765v62.585H76.81zm0-33.135V14.44l3.336-.025c4.158 0 5.751 2.514 5.751 7.518s-1.568 7.518-5.75 7.518h-3.337zM134.646.622v39.06h-20.043V.622h20.043zM171.942 0h-27.287v62.585h27.287c17.354 0 24.45-9.933 24.45-31.293C196.392 9.76 189.296 0 171.942 0zm-7.245 45.059V17.526h2.091c5.926 0 8.341 4.68 8.341 13.766 0 9.087-2.415 13.767-8.341 13.767h-2.091zM203.911 0h44.242v17.526h-24.2v6.92h20.615V37.79h-20.615v6.846H249v17.949h-45.065L203.911 0zm-68.817 52.652c0 5.774-4.682 10.455-10.457 10.455s-10.457-4.68-10.457-10.455 4.682-10.456 10.457-10.456 10.457 4.681 10.457 10.456z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#zdgx7epcia)">
      <path
        fill="#000"
        d="M-4.955 67.29H-43.62L25.296-63.83h38.666L-4.955 67.29z"
      />
      <path
        fill="#784F17"
        d="M33.338 67.29H-5.353L63.588-63.83h38.666L33.338 67.29z"
      />
      <path
        fill="#FE0000"
        d="M71.605 67.29H32.94l68.917-131.12h38.69L71.606 67.29z"
      />
      <path
        fill="#FD8C00"
        d="M109.898 67.29H71.207l68.941-131.12h38.666L109.898 67.29z"
      />
      <path
        fill="#FFE500"
        d="M148.19 67.29h-38.691l68.917-131.12h38.69L148.19 67.29z"
      />
      <path
        fill="#119F0B"
        d="M186.457 67.29h-38.665l68.916-131.12h38.691L186.457 67.29z"
      />
      <path
        fill="#0644B3"
        d="M224.75 67.29h-38.691L255-63.83h38.666L224.75 67.29z"
      />
      <path
        fill="#C22EDC"
        d="M263.017 67.29h-38.665l68.916-131.12h38.691L263.017 67.29z"
      />
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M87.24 96.142c0 10.904 4.98 15.858 14.491 15.858 9.536 0 14.491-4.979 14.491-15.858V92.01c0-10.904-4.955-15.858-14.491-15.858s-14.49 4.954-14.49 15.858v4.132zm14.491-9.659c2.166 0 3.461 1.096 3.461 5.054v5.053c0 3.959-1.295 5.054-3.461 5.054s-3.46-1.095-3.46-5.054v-5.053c0-3.958 1.294-5.054 3.46-5.054zm-90.03 24.82V76.824H.673v34.479h11.03zM36.75 95.62H36.2c-.448-1.917-1.096-4.357-1.892-6.299l-5.154-12.497h-11.95v34.454h10.108v-9.46c0-2.788-.374-6.198-.698-8.315h.548c.473 1.942 1.12 4.357 1.892 6.299l4.681 11.501H46.16V76.824H36.052v10.48c0 2.789.373 6.2.697 8.316zm26.59-18.796h11.03v23.899h11.03v10.58h-22.06V76.824zm76.012 18.796h.548c-.324-2.117-.697-5.527-.697-8.315v-10.48h10.108v34.478h-12.424l-4.68-11.501c-.772-1.942-1.419-4.357-1.892-6.299h-.548c.323 2.117.697 5.527.697 8.315v9.46h-10.109V76.824h11.951l5.154 12.497c.797 1.942 1.419 4.382 1.892 6.299zm30.525-18.796h-15.038v34.504h15.038c9.56 0 13.469-5.477 13.469-17.252 0-11.875-3.909-17.252-13.469-17.252zm-3.984 24.82v-15.16l1.145-.026c3.262 0 4.606 2.59 4.606 7.593 0 5.029-1.344 7.593-4.606 7.593h-1.145zM200.599 112c-9.511 0-14.49-4.954-14.49-15.858V92.01c0-10.904 4.954-15.858 14.49-15.858 9.536 0 14.491 4.954 14.491 15.858v4.132c0 10.879-4.98 15.858-14.491 15.858zm3.436-20.463c0-3.958-1.295-5.054-3.461-5.054s-3.46 1.096-3.46 5.054v5.053c0 3.959 1.294 5.054 3.46 5.054s3.461-1.095 3.461-5.054v-5.053zm34.707 4.082h-.548c-.448-1.916-1.095-4.356-1.892-6.298l-5.154-12.497h-11.95v34.454h10.108v-9.46c0-2.788-.373-6.198-.697-8.315h.548c.473 1.942 1.12 4.357 1.892 6.299l4.681 11.501h12.423V76.824h-10.108v10.48c0 2.789.373 6.2.697 8.316z"
      clipRule="evenodd"
    />
  </svg>
)
