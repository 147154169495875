import React from 'react'
import { InternalIconProps } from '../Icon.types'

const Snapchat = ({ colors: { primary }, ...props }: InternalIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill={primary}
      fillRule="evenodd"
      d="M26 6H6v20h20V6zM4 4v24h24V4H4z"
      clipRule="evenodd"
    />
    <path
      fill={primary}
      d="M16.075 22c-.772 0-1.108-.183-1.765-.677-.594-.448-.975-.694-2.078-.464-.43.09-.421.048-.514-.404-.05-.239-.085-.384-.168-.398-.896-.147-1.426-.364-1.532-.627-.05-.126.01-.226.102-.242 1.169-.205 2.113-1.237 2.523-2.254l.003-.007c.103-.224.124-.417.061-.574-.14-.354-.595-.428-.92-.566-.225-.095-.594-.294-.545-.569.045-.252.429-.411.634-.31.515.258.928.18 1.052.058-.006-.123.072-.256.072-.382 0-.87-.21-1.955.051-2.576 1.124-2.684 4.873-2.67 5.99-.001.261.622.197 1.706.145 2.579l-.002.041-.02.339c.025.023.121.105.319.114.167-.008.36-.065.573-.171.25-.125.7.066.705.353.003.133-.09.333-.548.525-.325.137-.78.212-.92.566-.063.157-.043.35.06.573l.004.008c.032.08.803 1.952 2.522 2.253.073.013.125.082.12.16-.001.028-.007.056-.018.084-.105.261-.635.478-1.531.625-.084.014-.12.16-.168.396-.074.36-.062.485-.515.39-1.09-.226-1.504.047-2.077.479-.423.318-.9.679-1.615.679z"
    />
  </svg>
)

export default Snapchat
