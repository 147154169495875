import React from 'react'
import { InternalIconProps } from '../Icon.types'

const Accessibility = ({
  colors: { primary, secondary },
  ...props
}: InternalIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill={secondary}
      d="M16 9c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
    />
    <path
      fill={primary}
      fillRule="evenodd"
      d="M27.83 6.352l1.598 6.276-1.918.506c-1.839.485-3.865.84-5.843 1.074V30H10.333V14.208c-1.978-.235-4.004-.589-5.843-1.074l-1.917-.506L4.17 6.352l1.95.514c1.744.46 3.687.77 5.67.948-.153-.443-.235-.918-.235-1.414 0-2.459 2.03-4.4 4.444-4.4s4.445 1.941 4.445 4.4c0 .496-.083.971-.235 1.414 1.982-.179 3.926-.489 5.67-.948l1.95-.514zM18.648 9.93c-.892.047-1.779.07-2.648.07-.87 0-1.756-.023-2.648-.07-2.496-.133-5.028-.459-7.306-1.02-.146-.035-.29-.072-.435-.11l-.61 2.4c.166.044.335.087.506.129.47.115.952.223 1.442.321 1.755.354 3.614.602 5.385.75V28h2.444v-7.2h2.444V28h2.445V12.4c1.77-.148 3.63-.396 5.384-.75.49-.098.973-.206 1.443-.321.17-.042.339-.085.506-.129l-.611-2.4c-.144.038-.289.075-.435.11-2.278.561-4.81.887-7.306 1.02zm-.798-1.966c.37-.42.595-.968.595-1.564 0-1.32-1.1-2.4-2.445-2.4-1.344 0-2.444 1.08-2.444 2.4 0 .596.224 1.143.594 1.564.45.511 1.113.836 1.85.836.737 0 1.401-.325 1.85-.836z"
      clipRule="evenodd"
    />
  </svg>
)

export default Accessibility
