import React from 'react'
const SponsorHeadline = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="a" fill="#fff">
      <path
        d="m.614 11.647c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615-5.2-11.615-11.615-11.615-11.615 5.2-11.615 11.615zm15.246-5.695a.324.324 0 0 1 .469.34l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.552l-4.06.59-1.816 3.678a.323.323 0 0 1 -.578 0l-1.816-3.678-4.06-.59a.323.323 0 0 1 -.18-.552l2.938-2.864-.693-4.043a.324.324 0 0 1 .468-.341l3.633 1.908 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <mask id="b" fill="#fff">
      <path
        d="m.614 11.898c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615c0-6.414-5.2-11.614-11.615-11.614s-11.615 5.2-11.615 11.614zm15.246-5.695a.324.324 0 0 1 .469.341l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.55l-4.06.59-1.816 3.68a.323.323 0 0 1 -.578 0l-1.816-3.68-4.06-.59a.323.323 0 0 1 -.18-.55l2.938-2.864-.693-4.044a.324.324 0 0 1 .468-.341l3.632 1.909 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <mask id="c" fill="#fff">
      <path
        d="m.614 11.898c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615c0-6.414-5.2-11.614-11.615-11.614s-11.615 5.2-11.615 11.614zm15.246-5.695a.324.324 0 0 1 .469.341l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.55l-4.06.59-1.816 3.68a.323.323 0 0 1 -.578 0l-1.816-3.68-4.06-.59a.323.323 0 0 1 -.18-.55l2.938-2.864-.693-4.044a.324.324 0 0 1 .468-.341l3.632 1.909 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <mask id="d" fill="#fff">
      <path
        d="m.614 11.898c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615c0-6.414-5.2-11.614-11.615-11.614s-11.615 5.2-11.615 11.614zm15.246-5.695a.324.324 0 0 1 .469.341l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.55l-4.06.59-1.816 3.68a.323.323 0 0 1 -.578 0l-1.816-3.68-4.06-.59a.323.323 0 0 1 -.18-.55l2.938-2.864-.693-4.044a.324.324 0 0 1 .468-.341l3.632 1.909 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <mask id="e" fill="#fff">
      <path
        d="m.614 11.898c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615c0-6.414-5.2-11.614-11.615-11.614s-11.615 5.2-11.615 11.614zm15.246-5.695a.324.324 0 0 1 .469.341l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.55l-4.06.59-1.816 3.68a.323.323 0 0 1 -.578 0l-1.816-3.68-4.06-.59a.323.323 0 0 1 -.18-.55l2.938-2.864-.693-4.044a.324.324 0 0 1 .468-.341l3.632 1.909 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <mask id="f" fill="#fff">
      <path
        d="m.614 11.898c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615c0-6.414-5.2-11.614-11.615-11.614s-11.615 5.2-11.615 11.614zm15.246-5.695a.324.324 0 0 1 .469.341l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.55l-4.06.59-1.816 3.68a.323.323 0 0 1 -.578 0l-1.816-3.68-4.06-.59a.323.323 0 0 1 -.18-.55l2.938-2.864-.693-4.044a.324.324 0 0 1 .468-.341l3.632 1.909 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <mask id="g" fill="#fff">
      <path
        d="m.614 11.898c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615c0-6.414-5.2-11.614-11.615-11.614s-11.615 5.2-11.615 11.614zm15.246-5.695a.324.324 0 0 1 .469.341l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.55l-4.06.59-1.816 3.68a.323.323 0 0 1 -.578 0l-1.816-3.68-4.06-.59a.323.323 0 0 1 -.18-.55l2.938-2.864-.693-4.044a.324.324 0 0 1 .468-.341l3.632 1.909 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <mask id="h" fill="#fff">
      <path
        d="m.614 11.898c0 6.415 5.2 11.615 11.615 11.615s11.615-5.2 11.615-11.615c0-6.414-5.2-11.614-11.615-11.614s-11.615 5.2-11.615 11.614zm15.246-5.695a.324.324 0 0 1 .469.341l-.693 4.044 2.938 2.864a.324.324 0 0 1 -.18.55l-4.06.59-1.816 3.68a.323.323 0 0 1 -.578 0l-1.816-3.68-4.06-.59a.323.323 0 0 1 -.18-.55l2.938-2.864-.693-4.044a.324.324 0 0 1 .468-.341l3.632 1.909 3.631-1.91z"
        fill="#fff"
        fillRule="evenodd"
      />
    </mask>
    <g fill="none" fillRule="evenodd" mask="url(#a)">
      <path
        d="m-8.54 19.527-2.127-2.128 25.401-25.399 2.126 2.128z"
        fill="#2c77e5"
      />
      <path
        d="m-6.413 21.905-2.127-2.127 25.4-25.4 2.127 2.127z"
        fill="#00a3bd"
        mask="url(#b)"
        transform="translate(0 -.251)"
      />
      <path
        d="m-4.285 24.032-2.128-2.127 25.4-25.4 2.127 2.127z"
        fill="#7de2d1"
        mask="url(#c)"
        transform="translate(0 -.251)"
      />
      <path
        d="m-2.159 26.159-2.127-2.127 25.4-25.4 2.127 2.127z"
        fill="#c3d30e"
        mask="url(#d)"
        transform="translate(0 -.251)"
      />
      <path
        d="m-.032 28.286-2.127-2.128 25.4-25.4 2.127 2.128z"
        fill="#ffd95e"
        mask="url(#e)"
        transform="translate(0 -.251)"
      />
      <path
        d="m2.095 30.413-2.127-2.127 25.4-25.4 2.127 2.127z"
        fill="#ff5b44"
        mask="url(#f)"
        transform="translate(0 -.251)"
      />
      <path
        d="m4.222 32.54-2.128-2.127 25.401-25.4 2.127 2.127z"
        fill="#fc4984"
        mask="url(#g)"
        transform="translate(0 -.251)"
      />
      <path
        d="m6.35 34.666-2.129-2.126 25.4-25.4 2.127 2.127z"
        fill="#bc01ff"
        mask="url(#h)"
        transform="translate(0 -.251)"
      />
    </g>
  </svg>
)

export default SponsorHeadline
