export const colors = {
  black: '#000000',
  darkGrey: '#5B5B5B',
  mediumGrey: '#E1E1E1',
  lightGrey: '#EFEFEF',
  white: '#FFFFFF',
  yellow: '#FFD95E',
  tomato: '#FF7A67',
  pink: '#ED2C6E',
  fuscia: '#FF88F1',
  electricPurple: '#BC01FF',
  darkPurple: '#660C63',
  indigo: '#2D2F7F',
  darkIndigo: '#20215A',
  greyBlue: '#5770D1',
  beachBlue: '#2C77E5',
  skyBlue: '#2ACFFF',
  bondiBlue: '#00A3BD',
  darkCyan: '#008296',
  eucalyptusGreen: '#2CDA9D',
  lemonGreen: '#C3D30E',
  green: '#34C30A',
  amber: '#FFCA00',
  red: '#D0021B',
  gold: '#FFE16C',
  silver: '#E0DFDC',
  bronze: '#B59035',
}
