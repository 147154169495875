import React from 'react'
import { InternalIconProps } from '../Icon.types'

const Support = ({
  colors: { primary, secondary },
  ...props
}: InternalIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill={primary}
      fillRule="evenodd"
      d="M9 15.992h3.306c.156 0 .376.046.678.164.298.116.615.277.966.463l.145.077c.295.156.62.33.925.464.342.151.79.317 1.256.317h2.77c.262 0 .58.255.59.724.004.193-.058.339-.157.437-.095.094-.325.243-.846.243h-3.91v2h3.91c.92 0 1.7-.276 2.251-.82.18-.178.325-.375.438-.581.51-.266 1.05-.557 1.568-.836l.819-.44c.89-.475 1.558-.812 1.881-.92l.003-.002c.488-.166.814-.141 1.004-.082.186.058.29.162.346.264.104.192.1.51-.28.743l-.025.015-.024.017c-1.753 1.222-3.582 2.324-5.477 3.298-.312.13-.652.292-.983.45l-.37.174c-.474.222-.946.429-1.424.584-.952.31-1.835.384-2.684.013-.897-.393-1.742-.785-2.468-1.121l-.582-.27c-.413-.19-.78-.356-1.065-.475-.143-.06-.28-.113-.402-.154l-.003-.001c-.08-.027-.287-.097-.508-.097H9v2h1.541c.057.02.138.052.25.098.248.104.584.255.998.446l.566.262h.001c.728.338 1.599.741 2.518 1.144 1.473.645 2.908.446 4.104.057.597-.194 1.158-.443 1.653-.674.152-.07.294-.138.429-.202.325-.155.609-.29.88-.402l.038-.015.037-.02c1.98-1.015 3.89-2.164 5.721-3.44 1.255-.788 1.578-2.26.962-3.388-.65-1.191-2.111-1.675-3.748-1.117-.515.173-1.348.606-2.182 1.05-.278.149-.564.302-.85.457l-.49.263c-.358-.902-1.173-1.682-2.381-1.682h-2.77-.01l-.024-.003c-.022-.004-.053-.01-.094-.022-.084-.024-.189-.063-.32-.121-.234-.103-.485-.237-.785-.396l-.16-.084c-.35-.186-.758-.396-1.173-.558-.412-.161-.897-.3-1.405-.3H9v2zm1.479 6.629s.004 0 .014.004l-.014-.004z"
      clipRule="evenodd"
    />
    <path fill={secondary} stroke={primary} strokeWidth="2" d="M4 13H8V25H4z" />
    <mask id="prefix__a" fill="#fff">
      <path d="M24.985 7.787c.004-.362-.064-.722-.2-1.058-.136-.336-.337-.642-.593-.9-.255-.26-.56-.466-.895-.608-.336-.142-.696-.217-1.061-.221-.443.001-.878.11-1.27.316-.39.206-.466.184-.966.684-.5-.5-.59-.478-.982-.684C18.628 5.11 18.192 5 17.75 5c-.366.004-.726.079-1.062.221-.335.142-.64.349-.895.607-.255.26-.457.565-.593.901-.136.336-.204.696-.2 1.058.072.83.438 1.61 1.032 2.2L20.002 14l3.97-4.018c.585-.594.947-1.369 1.028-2.195" />
    </mask>
    <path
      fill={secondary}
      d="M24.985 7.787c.004-.362-.064-.722-.2-1.058-.136-.336-.337-.642-.593-.9-.255-.26-.56-.466-.895-.608-.336-.142-.696-.217-1.061-.221-.443.001-.878.11-1.27.316-.39.206-.466.184-.966.684-.5-.5-.59-.478-.982-.684C18.628 5.11 18.192 5 17.75 5c-.366.004-.726.079-1.062.221-.335.142-.64.349-.895.607-.255.26-.457.565-.593.901-.136.336-.204.696-.2 1.058.072.83.438 1.61 1.032 2.2L20.002 14l3.97-4.018c.585-.594.947-1.369 1.028-2.195"
    />
    <path
      fill={primary}
      d="M22.236 5l.02-2h-.027l.007 2zM20 6l-1.414 1.414L20 8.828l1.414-1.414L20 6zm-2.25-1l.006-2h-.028l.021 2zM15 7.787l-2 .021.001.076.007.076L15 7.787zm1.032 2.2l1.422-1.406-.006-.007-.006-.006-1.41 1.42zM20.002 14l-1.421 1.407 1.422 1.438 1.422-1.44L20.003 14zm3.97-4.018l1.423 1.406.004-.004-1.426-1.402zm3.013-2.174c.007-.627-.111-1.248-.346-1.83L22.93 7.48c.037.09.055.188.054.285l4 .043zm-.346-1.83c-.235-.58-.583-1.108-1.023-1.554l-2.848 2.809c.071.072.126.156.163.247l3.708-1.501zm-1.023-1.554c-.44-.446-.963-.8-1.54-1.045l-1.559 3.684c.095.04.18.098.252.17l2.847-2.81zm-1.54-1.045c-.575-.244-1.194-.372-1.82-.379l-.041 4c.104.001.207.023.302.063l1.56-3.684zM22.23 3c-.764.002-1.517.19-2.194.546l1.864 3.54c.105-.056.223-.086.343-.086l-.013-4zm-2.194.546c-.07.037-.104.053-.204.102-.09.045-.225.114-.38.212-.315.2-.582.442-.865.726l2.828 2.828c.217-.216.238-.214.183-.178-.024.015-.036.02.014-.006.04-.02.163-.079.288-.145l-1.864-3.539zm1.38 1.04c-.295-.295-.57-.538-.895-.74-.155-.096-.292-.163-.378-.205-.093-.045-.13-.062-.191-.095l-1.865 3.54c.134.07.255.127.304.15.057.029.048.026.027.013-.058-.036-.037-.04.169.165l2.828-2.828zm-1.464-1.04c-.677-.356-1.43-.544-2.195-.546l-.013 4c.12 0 .238.03.343.085l1.865-3.539zM17.728 3c-.625.007-1.243.135-1.82.38l1.56 3.683c.095-.04.198-.062.303-.063l-.043-4zm-1.82.38c-.576.243-1.099.598-1.539 1.044l2.848 2.809c.07-.072.156-.13.251-.17l-1.56-3.684zM14.37 4.423c-.44.446-.787.974-1.022 1.555l3.707 1.5c.037-.09.092-.174.163-.246l-2.848-2.81zm-1.022 1.555c-.236.58-.354 1.202-.347 1.83l4-.044c-.001-.097.017-.194.054-.285l-3.707-1.501zm-.34 1.981c.114 1.304.688 2.525 1.616 3.447l2.819-2.839c-.26-.258-.418-.597-.45-.955l-3.984.347zm1.604 3.434l3.97 4.013 2.843-2.814-3.97-4.012-2.843 2.813zm6.814 4.012l3.97-4.018-2.845-2.811-3.97 4.017 2.845 2.812zm3.974-4.022c.904-.92 1.466-2.12 1.592-3.403l-3.982-.388c-.036.37-.198.718-.463.988l2.853 2.803z"
      mask="url(#prefix__a)"
    />
  </svg>
)

export default Support
